@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manjari:wght@100;400;700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.8rem;
  font-family: "Montserrat Alternates", sans-serif;
  overflow-x: hidden;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture,
svg {
  max-width: 100%;
  display: block;
  max-height: 100%;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

.montserrat-alternates-thin {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.montserrat-alternates-thin-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.montserrat-alternates-extralight {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.montserrat-alternates-extralight-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.montserrat-alternates-light {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.montserrat-alternates-light-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.montserrat-alternates-regular {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-alternates-regular-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.montserrat-alternates-medium {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 1.6rem;
}

.montserrat-alternates-medium-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.montserrat-alternates-semibold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.montserrat-alternates-semibold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.montserrat-alternates-bold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.montserrat-alternates-bold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.montserrat-alternates-extrabold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.montserrat-alternates-extrabold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.montserrat-alternates-black {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.montserrat-alternates-black-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.map__tooltip {
  width: 50rem;
  height: 12rem;
  font-size: 1.8rem;
  font-family: "Manjari";
  text-align: center;
  color: #caeab944;
}

.highcharts-tooltip {
  width: 25rem;
  height: 20rem;
}

.highcharts-data-label-box {
  font-family: "Montserrat";
  font-size: 21.47px;
  font-weight: 400;
  color: #05711c;
}
.highcharts-data-label {
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 21.47px;
  font-weight: 400;
  color: #05711c;
  outline: none;
}